import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: {
      open: true,
      full: true,
    }
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: '登录',
      open: true,
    }
  },
  {
    path: '/test/express',
    name: '测试-快递验证',
    component: () => import('@/test/express.vue'),
    meta: {
      title: '测试-快递验证',
      open: true,
    }
  },
  {
    path: '/search/imei',
    name: 'imei查询',
    component: () => import('@/views/searchImei.vue'),
    meta: {
      title: 'imei查询',
      open: true,
    }
  },
  {
    path: '/coupon/contract/:imei',
    name: '优惠券合同列表',
    component: () => import('@/views/coupon/contract.vue'),
    meta: {
      title: '优惠券合同列表',
      open: true,
    }
  },
  {
    path: '/coupon/coupon/:id',
    name: '优惠券列表',
    component: () => import('@/views/coupon/coupon.vue'),
    meta: {
      title: '优惠券',
      open: true,
    }
  },
  {
    path: '/coupon/qr/:id',
    name: '优惠券二维码',
    component: () => import('@/views/coupon/couponQR.vue'),
    meta: {
      title: '优惠券二维码',
      open: true,
    }
  },
  {
    path: '/coupon/store/list',
    name: '门店列表',
    component: () => import('@/views/coupon/storeList.vue'),
    meta: {
      title: '门店列表',
      open: true,
      keepAlive: true,
    }
  },
  {
    path: '/coupon/store/map',
    name: '门店位置',
    component: () => import('@/views/coupon/storeMap.vue'),
    meta: {
      title: '门店位置',
      open: true,
    }
  },
  {
    path: '/coupon/transfer/:id',
    name: '保障服务转移',
    component: () => import('@/views/coupon/transfer.vue'),
    meta: {
      title: '保障服务转移',
      open: true,
    }
  },
  {
    path: '/apply/explain',
    name: '服务申请说明',
    component: () => import('@/views/apply/explain.vue'),
    meta: {
      title: '服务申请说明',
      open: true,
    }
  },
  {
    path: '/apply/contract/:imei',
    name: '服务申请合同',
    component: () => import('@/views/apply/contract.vue'),
    meta: {
      title: '服务申请',
      open: true,
    }
  },
  {
    path: '/apply/authentication/:id',
    name: '服务申请-身份验证',
    component: () => import('@/views/apply/authentication.vue'),
    meta: {
      title: '服务申请',
      open: true,
    }
  },
  {
    path: '/apply/dataText/:id',
    name: '服务申请填写资料1',
    component: () => import('@/views/apply/dataText.vue'),
    meta: {
      title: '服务申请',
      open: true,
    }
  },
  {
    path: '/apply/dataFile/:id',
    name: '服务申请填写资料2',
    component: () => import('@/views/apply/dataFile.vue'),
    meta: {
      title: '服务申请',
      open: true,
    }
  },
  {
    path: '/imei/search',
    name: '修改imei',
    component: () => import('@/views/imei/search.vue'),
    meta: {
      title: '修改IMEI号-搜索',
      open: true,
    }
  },
  {
    path: '/imei/modify/:id',
    name: '修改imei-修改',
    component: () => import('@/views/imei/modify.vue'),
    meta: {
      title: '修改IMEI号',
      open: true,
    }
  },
  {
    path: '/imei/contract',
    name: '修改imei-合同',
    component: () => import('@/views/imei/contract.vue'),
    meta: {
      title: '修改IMEI号',
      open: true,
    }
  },
  {
    path: '/user/imei',
    name: '修改用户信息',
    component: () => import('@/views/user/Imei.vue'),
    meta: {
      title: '修改信息',
      open: true,
    }
  },
  {
    path: '/user/update/:imei',
    name: '修改用户信息',
    component: () => import('@/views/user/Update.vue'),
    meta: {
      title: '修改信息',
      open: true,
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
