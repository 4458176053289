var apis = {};
if(process.env.VUE_APP_FLAG==="home"||process.env.VUE_APP_FLAG==undefined){
  // 开发环境和默认地址
  // apis = {
  //   default: '//devtest.hollardchina.com.cn:20831/mobile',
	//   default2: '//devtest.tc.hollardchina.com.cn',
  //   link: '//devtest.hollardchina.com.cn',
  // };
  apis = {
    default: '//mobile.hollardchina.com.cn',
    link: '//insuer.hollardchina.com.cn',
  };
}else if(process.env.VUE_APP_FLAG==="dev"){
  // 测试环境
  apis = {
    default: '//devtest.hollardchina.com.cn:20831/mobile',
	  default2: '//devtest.tc.hollardchina.com.cn',
    link: '//devtest.hollardchina.com.cn',
  };
}else if(process.env.VUE_APP_FLAG==="busi"){
  // 生产测试环境
  apis = {
    default: '//busitest.mobile.hollardchina.com.cn',
    link: '//busitest.hollardchina.com.cn',
  };
}else if(process.env.VUE_APP_FLAG==="prod"){
  // 生产环境
  apis = {
    default: '//mobile.hollardchina.com.cn',
    link: '//insuer.hollardchina.com.cn',
  };
}


export default {
  apis: apis,
  token: {
		'accessToken': '',
		'refreshToken': ''
	},
}