export default {
  // 节流阀
  throttle (callBack, time) {
    let timer = null
    return function() {
      if (!timer) {
        timer = setTimeout(() => {
          callBack()
          timer = null
        }, time)
      }
    }
  },
  // 时间格式化
  timeFormat (value) {
    if (value) {
      let add0 = (m) => {
        return m < 10 ? '0' + m : m
      }
      var time = new Date(value)
      var y = time.getFullYear()
      var m = time.getMonth() + 1
      var d = time.getDate()
      var h = time.getHours()
      var mm = time.getMinutes()
      var s = time.getSeconds()
      return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s)
    } else {
      return ''
    }
  },
  // 日期格式化
  dateFormat (value) {
    if (value) {
      let add0 = (m) => {
        return m < 10 ? '0' + m : m
      }
      var time = new Date(value)
      var y = time.getFullYear()
      var m = time.getMonth() + 1
      var d = time.getDate()
      return y + '-' + add0(m) + '-' + add0(d)
    } else {
      return ''
    }
  },
  // 手机号验证
  isPhone (phone) {
    if(!(/^1[3456789]\d{9}$/.test(phone))){ 
      return false;
    }
    return true
  },
  // 身份证验证
  isIdCard (str) {
    if(!(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(str))){ 
      return false;
    }
    return true
  },
  // 判断微信
  isWeixin () {
    var agent = navigator.userAgent.toLowerCase();
    if (agent.match(/MicroMessenger/i) == "micromessenger") {
      return true;
    } else {
      return false;
    }
  },
  // 百度坐标转换高德坐标
  bd_decrypt: function (bd_lng, bd_lat) {
    var X_PI = Math.PI * 3000.0 / 180.0;
    var x = bd_lng - 0.0065;
    var y = bd_lat - 0.006;
    var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
    var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
    var gg_lng = z * Math.cos(theta);
    var gg_lat = z * Math.sin(theta);
    return {lng: gg_lng, lat: gg_lat}
  }
}