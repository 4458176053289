import Vue from 'vue'
import Cube from 'cube-ui'
import App from './App.vue'
import './assets/css/init.scss'
import router from './router'
import store from './store'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import init from './common/init'
import utils from './common/utils'
import AMap from 'vue-amap' // 地图

// TODO
import md5 from 'js-md5'

let url1 = 'https://staging-neon-www.aihuishou.com/boot/xintong?'

var url2 = 'authorization=simple&timestamp='+(Date.parse(new Date()) / 1000)+'&user_id=12356526789'

var url3 = md5(url2 + '&secret=3e289029f60ed2ffeec247756d563b1e')

console.log(url1 + url2 + '&sign=' + url3)



// 地图
Vue.use(AMap)
AMap.initAMapApiLoader({
  key: 'f1f19fcc0ea5ef91efd9edb2a47cd112',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation'],
  v: '1.4.4',
  uiVersion: '1.0.11'
})


Vue.use(Cube)
Vue.use(VueCookies)
Vue.prototype.$init = init
Vue.prototype.$utils = utils
Vue.prototype.$axios = axios


/**
 * ajax封装
 */
function verificationCode (data) {
  var dataObj = data;
  try {
    dataObj = JSON.parse(data);
    if(dataObj.header.code == 'B1001'){
      router.push('/login')
      Cube.Toast.$create({
        txt: '请重新登录',
        type: 'warn'
      }).show()
    }
  } catch {
    if(dataObj.header.code == 'B1001'){
      router.push('/login')
      Cube.Toast.$create({
        txt: '请重新登录',
        type: 'warn'
      }).show()
    }
  }
  return dataObj;
}
Vue.prototype.$http = {
	post (url, data = {}, type = 1) {
	    let baseUrl = type == 1 ? init.apis.default : init.apis.default2;
		return axios({
			method: 'post',
			baseURL: baseUrl,
			url: url,
			data: data,
			timeout: 600000,
			headers: {
				'token': Vue.prototype.$init.token.accessToken
			},
			transformResponse: [ function (data) {
				return verificationCode(data);
			}],
		})
	},
  get (url, data = {}) {
    return axios({
      method: 'get',
      baseURL: init.apis.default,
      url: url,
      params: data,
      timeout: 600000,
      headers: {
        'token': Vue.prototype.$init.token.accessToken
      },
      transformResponse: [ function (data) {
        return verificationCode(data);
      }],
    })
  }
}


/**
 * 获取用户登录
 */
function getPower () {
  // TODO 测试用
  // if(VueCookies.get('insure.session.id')){
  //   Vue.prototype.$init.token.accessToken = VueCookies.get('insure.session.id')
  // }

  Vue.prototype.$init.token.accessToken = '12345'
}
getPower();


/**
 * 路径变化
 */
router.beforeEach((to, from, next) => {
  // title设置
  if (to.meta.title) {
    document.title = to.meta.title
  }else{
    document.title = '和德微服务'
  }
  // 权限验证
  if (to.meta.open) {
    next()
  } else if (Vue.prototype.$init.token.accessToken) {
    next()
  } else {
    next('/login')
  }
  // 轮询处理
  if(window.ticketIn){
    clearInterval(window.ticketIn)
  }
})
router.afterEach(() => {
  // Vue.nextTick(() => {
    store.commit('updateLoader', false)
  // })
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
